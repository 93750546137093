<template>
	<div class="d-flex flex-column">
		<v-row
			dense
			:style="`${depth == 0 ? 'background-color: rgba(200,200,200,0.2)' : ''}`"
			v-ripple="!!item.children"
			@click="() => (!!item.children ? click(item.key) : () => {})"
			class="mb-1 align-center treeRow">
			<v-col>
				<div
					style="user-select: none"
					:class="`mx-${3 * depth}`">
					<v-icon
						:class="{ 'spin-me': expanded.includes(item.key) }"
						:color="`${item.children ? 'kajot-text' : 'transparent'}`"
						class="mr-1">
						mdi-menu-right
					</v-icon>
					{{ item.name }}
				</div>
			</v-col>
			<v-col class="justify-right">
				<v-row dense>
					<v-layout v-if="loading">
						<v-skeleton-loader
							class="d-flex flex-row mr-8"
							v-for="n in 4"
							:key="n"
							type="button"
							width="28px"
							max-height="28px"
							:loading="loading"></v-skeleton-loader>
					</v-layout>
					<v-layout v-else>
						<v-checkbox
							v-for="checkbox of checkboxes"
							:key="item.key + '.' + checkbox.key"
							:readonly="readonly"
							:ripple="false"
							:color="checkbox.color"
							v-bind="isItemIndeterminate(item, checkbox.key)"
							:on-icon="'mdi-' + checkbox.icon + ' smallCon'"
							:off-icon="
								'mdi-' +
								checkbox.icon +
								'-outline kajot-disabled--text smallCon'
							"
							:indeterminate-icon="
								'mdi-' +
								checkbox.icon +
								'-outline ' +
								checkbox.color +
								'--text smallCon'
							"
							class="checkMenu"
							@click="$event.stopPropagation()"
							@change="
								(event) => handleChange(event, item, checkbox.key)
							"></v-checkbox>
					</v-layout>
				</v-row>
			</v-col>
		</v-row>
		<v-expand-transition>
			<div
				v-if="item.children"
				v-show="expanded.includes(item.key)">
				<PermissionTree
					v-for="childItem in item.children"
					:key="childItem.key"
					:item="childItem"
					:depth="depth + 1"
					:loading="loading"
					:selected="selected"
					:expanded="expanded"
					:checkboxes="checkboxes"
					@click="(c) => click(c)"
					@change="handleChange"
					:readonly="readonly" />
			</div>
		</v-expand-transition>
	</div>
</template>

<script>
export default {
	name: "PermissionTree",
	data() {
		return {
			expanding: false,
			opened: {},
		};
	},
	props: {
		item: Object,
		checkboxes: Array,
		readonly: Boolean,
		loading: Boolean,
		selected: Array,
		expanded: Array,
		depth: Number,
	},
	created() {
		this.$emit("click", this.item.key);
	},
	methods: {
		click(b) {
			this.$emit("click", b);
		},
		handleChange(event, item, checkboxKey) {
			this.$emit("change", event, item, checkboxKey);
		},
		isItemIndeterminate(item, perm) {
			const isLeaf = !item.children;
			if (isLeaf)
				return {
					indeterminate: false,
					"input-value": this.selected.includes(`${item.key}.${perm}`),
				};

			const childTuples = item.children.map((child) =>
				this.isItemIndeterminate(child, perm)
			);
			const allOn = childTuples.every(
				(childTuple) => childTuple["input-value"]
			);
			const allOff = childTuples.every(
				(childTuple) => !childTuple["input-value"]
			);
			const someIndetermined = childTuples.some(
				(childtuple) => childtuple.indeterminate
			);

			return {
				indeterminate: !(allOn || allOff) || someIndetermined,
				"input-value": allOn,
			};
		},
	},
};
</script>

<style>
.treeRow {
	position: relative;
}
.treeRow:hover::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--v-primary-base) !important;
	opacity: 0.3;
}

.spin-me {
	transform: rotate(90deg);
}

.checkMenu {
	max-height: 22px;
	padding: 0;
	margin: 0;
	position: relative;
	margin-right: 2vw;
}
</style>
