<template>
	<section>
		<v-row>
			<template v-for="(group, idx) of splitItems">
				<v-col
					:key="`TW${idx}`"
					:cols="12"
					:lg="12 / splitItems.length">
					<div
						:key="`${item.key}`"
						v-for="item of group">
						<PermissionTree
							:item="item"
							:loading="loading"
							:checkboxes="checkboxes"
							:expanded="expanded"
							:selected="selected"
							:depth="0"
							:readonly="readonly"
							@click="onExpand"
							@change="onParentChange" />
					</div>
				</v-col>
			</template>
		</v-row>
	</section>
</template>

<script>
import { constants } from "../../constants/constants";
import PermissionTree from "../users/PermissionTree.vue";

export default {
	props: {
		search: {
			default: "",
			type: String,
		},
		loading: {
			default: false,
			type: Boolean,
		},
		dense: {
			default: true,
			type: Boolean,
		},
		usersPerms: {
			default: () => ({}),
			type: Object,
		},
		color: {
			type: String,
			default: "primary",
		},
		readonly: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			selected: [],
			expanded: [],
			permissions: constants.permissions,
		};
	},
	components: { PermissionTree },
	computed: {
		checkboxes() {
			return [
				{ darken: 0, color: this.color, icon: "eye", key: "read" },
				{ darken: 0, color: this.color, icon: "pencil", key: "edit" },
				{ darken: 0, color: this.color, icon: "plus-circle", key: "create" },
				{ darken: 0, color: this.color, icon: "delete", key: "delete" },
			];
		},
		items() {
			function createItem(name, key) {
				return { name, key };
			}

			function createChildren(parent) {
				const list = createItem("List", parent.key + ".list");
				const detail = createItem("Detail", parent.key + ".detail");
				return [list, detail];
			}

			function createChild(parent, child) {
				const childNode = createItem(
					child.charAt(0).toUpperCase() + child.substring(1),
					parent.key + `.${child}`
				);
				return [childNode];
			}

			const base = [
				["Statistic", "statistic"],
				["Bonus", "bonus"],
				["Free Games", "free_games"],
				["Players", "player"],
				["Sessions", "session"],
				["Management", "management"],
				["Audit", "audit"],
				["Games", "meta_games"],
				["Winston", "winston"],
				["Casinos", "meta_casinos"],
				["Analytics", "analytics"],
			].map((el) => createItem(el[0], el[1]));

			base[2].children = createChildren(base[2]);
			base[3].children = createChildren(base[3]);
			base[4].children = createChildren(base[4]);
			base[7].children = createChild(base[7], "list");
			base[9].children = createChildren(base[9]);
			base[8].children = [
				["Casinos", "casinos"],
				["Games", "games"],
			].map((el, i) => createItem(el[0], base[8].key + "." + el[1]));

			base[8].children.forEach((element) => {
				element.children = createChildren(element);
			});

			base[5].children = [
				["Groups", "groups"],
				["Users", "users"],
			].map((el, i) => createItem(el[0], base[5].key + "." + el[1]));

			base[5].children.forEach((element) => {
				element.children = createChildren(element);
			});

			base[1].children = [
				["Groups", "groups"],
				["Group Configs", "group_configs"],
				["Group Themes", "group_themes"],
				["Bonus Config", "bonus_config"],
				["Levels", "levels"],
				["Paid Bonuses", "paid_bonuses"],
			].map((el, i) => createItem(el[0], base[1].key + "." + el[1]));

			base[1].children.forEach((element) => {
				element.children = createChildren(element);
			});

			return base;
		},
		splitItems() {
			return [
				[
					this.items[6],
					this.items[9],
					this.items[7],
					...this.items.slice(1, 2),
				],
				[
					this.items[0],
					this.items[10],
					this.items[8],
					...this.items.slice(2, 6),
				],
			];
		},
	},
	methods: {
		reset() {
			this.selected = [];
			this.walkTree(this.usersPerms);
		},
		async zeroOut() {
			this.selected = [];
			await this.walkTree({});
		},
		walkTree(obj, parentKey = undefined) {
			for (let key in obj) {
				if (typeof obj[key] === "number") {
					if (obj[key] & this.permissions.READ) {
						this.selected.push(
							(parentKey === undefined ? key : parentKey + "." + key) + ".read"
						);
					}
					if (obj[key] & this.permissions.EDIT) {
						this.selected.push(
							(parentKey === undefined ? key : parentKey + "." + key) + ".edit"
						);
					}
					if (obj[key] & this.permissions.CREATE) {
						this.selected.push(
							(parentKey === undefined ? key : parentKey + "." + key) +
								".create"
						);
					}
					if (obj[key] & this.permissions.DELETE) {
						this.selected.push(
							(parentKey === undefined ? key : parentKey + "." + key) +
								".delete"
						);
					}
				} else {
					this.walkTree(
						obj[key],
						parentKey === undefined ? key : parentKey + "." + key
					);
				}
			}
		},
		onParentChange(val, item, perm) {
			const newVal = !!val;

			const isLeaf = !item.children;
			if (isLeaf) {
				if (newVal) {
					if (!this.selected.includes(`${item.key}.${perm}`)) {
						this.selected.push(`${item.key}.${perm}`);
					}
				} else {
					const index = this.selected.findIndex(
						(val) => val === `${item.key}.${perm}`
					);
					if (index > -1) {
						this.selected.splice(index, 1);
					}
				}
				return;
			}

			for (const child of item.children) {
				this.onParentChange(newVal, child, perm);
			}
		},
		onExpand(b) {
			if (this.expanded.includes(b)) {
				this.expanded = this.expanded.filter((e) => e !== b);
			} else {
				this.expanded.push(b);
			}
		},
		gardener(branchArray) {
			const tree = {};
			branchArray.forEach(async (branch) => {
				const parts = branch?.split(".");
				let val = -1;
				switch (parts.pop()) {
					case "read":
						val = this.permissions.READ;
						break;
					case "edit":
						val = this.permissions.EDIT;
						break;
					case "create":
						val = this.permissions.CREATE;
						break;
					case "delete":
						val = this.permissions.DELETE;
						break;
				}
				const lowestKey = parts.pop();
				const base = { [lowestKey]: val };
				const subtree = parts.reduceRight(
					(prev, next) => ({ [next]: prev }),
					base
				);

				function walkSubtree(tree, subtree) {
					for (let key in subtree) {
						if (typeof subtree[key] === "number") {
							tree[key] = tree[key] | subtree[key];
						} else {
							tree[key] = tree[key] ?? {};
							walkSubtree(tree[key], subtree[key]);
						}
					}
				}

				walkSubtree(tree, subtree);
			});
			return tree;
		},
	},
	watch: {
		usersPerms: {
			async handler(newVal) {
				this.selected = [];
				await this.$nextTick();
				this.walkTree(newVal);
			},
			deep: true,
		},
		async selected() {
			await this.$emit("change", this.gardener(this.selected));
		},
	},
	async created() {
		this.selected = [];
		await this.$nextTick();
		await this.walkTree(this.usersPerms);
	},
};
</script>
