var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-row",
        [
          _vm._l(_vm.splitItems, function (group, idx) {
            return [
              _c(
                "v-col",
                {
                  key: `TW${idx}`,
                  attrs: { cols: 12, lg: 12 / _vm.splitItems.length },
                },
                _vm._l(group, function (item) {
                  return _c(
                    "div",
                    { key: `${item.key}` },
                    [
                      _c("PermissionTree", {
                        attrs: {
                          item: item,
                          loading: _vm.loading,
                          checkboxes: _vm.checkboxes,
                          expanded: _vm.expanded,
                          selected: _vm.selected,
                          depth: 0,
                          readonly: _vm.readonly,
                        },
                        on: { click: _vm.onExpand, change: _vm.onParentChange },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }